<!-- 上传派单到司机 -->
<template>
  <div>
    <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      custom-class="ele-dialog-form"
      :title="isUpdate?'上传订单状态':'上传订单状态'"
      @update:visible="updateVisible">

      <el-form
        ref="form"
        :model="form"
        label-width="130px">
        <el-form-item label="时间">
          <el-date-picker
            v-model="form.times"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="默认当前时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="选择司机：">
          <el-select v-model="form.value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button
          @click="updateVisible(false)">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          v-auths="[`${$config.uniquePrefix}orderModule:info:followup`]"
          @click="save">确认
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies, setCookies} from '@/utils/cookies'
import Config from '@/config'

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 上传图片
      dialogImageUrl: '',
      dialogVisible: false,
      upload_btn: false, // 是否隐藏上传图片按钮
      fileList:[],
      // 上传接口
      action: setting.apiBaseURL + 'common/upload/capacity',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      options: [{
        value: '1',
        label: '司机1'
      }, {
        value: '2',
        label: '司机2'
      }],

    };
  },


  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.form.remark = '';
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    // 获取下当前时间 年月日时分秒
    // 获取当前时间
    let currentTime = new Date();
    // 获取年份、月份、日期、小时、分钟、秒数
    let year = currentTime.getFullYear(); // 2021
    let month = (currentTime.getMonth() + 1).toString().padStart(2, '0'); // 01-12
    let day = currentTime.getDate().toString().padStart(2, '0'); // 01-31
    let hours = currentTime.getHours().toString().padStart(2, '0'); // 00-23
    let minutes = currentTime.getMinutes().toString().padStart(2, '0'); // 00-59
    let seconds = currentTime.getSeconds().toString().padStart(2, '0'); // 00-59
    this.form.times = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log(this.form.times)
  },

  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">

</style>
