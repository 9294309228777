<!-- 异常订单处理弹窗 -->
<template>
  <el-dialog
      width="30%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'异常处理':'异常处理'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="100px">

      <div style="margin-top: 10px;margin-bottom: 10px;">
        <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 5}"
            placeholder="请填写异常处理说明"
            v-model="form.explain">
        </el-input>
      </div>

    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

import {order_manage} from "@/api/orderModule";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

    };
  },
  // watch: {
  //   data() {
  //     if (this.data) {
  //       console.log(this.data)
  //       this.form = Object.assign({}, this.data);
  //       this.isUpdate = true;
  //     } else {
  //       this.form = {};
  //       this.isUpdate = false;
  //     }
  //   }
  // },
  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(this.form.explain == '' || this.form.explain == undefined){
            this.$message.error('请输入异常处理说明');
          }else {
            let data = {
              explain: this.form.explain
            }
            order_manage(this.data.id,data).then(res => {
              if (res.code === 200) {
                this.loading = true;
                this.updateVisible(false);
                this.$emit('done');
                this.loading = false;
                this.$message.success(res.msg);
              } else {
                this.loading = false;
                this.$message.error(res.msg);
              }
            }).catch(err => {
              this.loading = false;
              this.$message.error(err.msg);
            })
          }
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">

</style>
