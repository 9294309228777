<template>
  <!--我的任务订单-->
  <div style="padding: 20px;">
    <div v-if="Orderdetails == false && showEdit == false && AbnormalOrderdetails == false">
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
                :model="where"
                label-width="90px"
                class="ele-form-search"
                @keyup.enter.native="reload"
                @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="关键字:">
                    <el-input
                        clearable
                        v-model="where.keyword"
                        placeholder="订单号/案件号/客户/客户电话/客户/服务人员"/>
                  </el-form-item>
                </el-col>
                <!--<el-col :lg="6" :md="12">-->
                <!--  <el-form-item label="受理人:">-->
                <!--    <el-input-->
                <!--        clearable-->
                <!--        v-model="where.acceptor_nickname"-->
                <!--        placeholder="请输入受理人"/>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <el-col :lg="8" :md="12">
                  <el-form-item label="下单时间:">
                    <el-date-picker
                      value-format="yyyy-MM-dd"
                      v-model="where.times"
                        type="daterange"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                    <!--<DatePicker v-model="where.order_time" :transfer="true" @on-change="datechange" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择"/>-->
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12" style="text-align: center;">
                  <div class="ele-form-actions">
                    <el-button
                        type="primary"
                        class="ele-btn-icon"
                        @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="Datalist-botton" style="margin-left: 20px">
                <el-button class="custom-button" @click="CreatePenaltyOrder()" v-auths="[`${$config.uniquePrefix}orderModule:myMission:order`]">
                  <!--el-icon-devops 是自己命名的-->
                  <i class="el-icon-xiadan" />
                  <span style="vertical-align: middle">下单</span>
                </el-button>
                <el-button class="custom-button" style="background: #FF9E1C!important;border-color: #FF9E1C!important;color: #FFFFFF!important;" @click="batch(null)" v-auths="[`${$config.uniquePrefix}orderModule:myMission:batch`]">
                  <!--el-icon-devops 是自己命名的-->
                  <i class="el-icon-piliangzhuanjiao" />
                  <span style="vertical-align: middle">批量转交</span>
                </el-button>

              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <div class="table_div">
              <div v-for="(item,index) in activeList" :key="index" class="table_div_kuai" :class="{table_div_kuai1:index==isShow}" @click="clickactive(item,index)">
                <el-badge value="" class="item">
                  <span>
                    {{item.label}}
                  </span>
                </el-badge>
              </div>
            </div>

            <!-- 数据表格 -->
            <my-table
                v-if="isShow != 1"
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :cellStyle="cellStyle"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >

              <template v-slot:appointment_label= "{ scope }">
                <span style="margin-right: 10px;">{{scope.row.appointment_label}}</span>
                <span>{{scope.row.appointment_overtime}}</span>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="warning" @click="Todetails(scope.row)" v-auths="[`${$config.uniquePrefix}orderModule:info:info`]">详情</el-link>
                <el-link :underline="false" type="warning" @click="transfer(scope.row)" v-auths="[`${$config.uniquePrefix}orderModule:myMission:careof`]" v-if="scope.row.order_status != 6">转交</el-link>
                <el-link :underline="false" type="warning" @click="shouli(scope.row)" v-if="isShow != 11 && scope.row.status_label == '待受理'" v-auths="[`${$config.uniquePrefix}orderModule:myMission:orderreceiving`]">接单</el-link>
                <el-link :underline="false" type="warning" style="color: #5976E1!important;" @click="distributeLeaflets(scope.row)" v-if="isShow != 11 && scope.row.status_label == '待派单'" v-auths="[`${$config.uniquePrefix}orderModule:myMission:grouporder`]">派单</el-link>
                <el-link :underline="false" type="warning" @click="ToExamine(scope.row)" v-if="isShow == 11 || isShow == 9">审核</el-link>
                <el-link :underline="false" type="warning" v-if="isShow == 2 || isShow == 3 || isShow == 4 || isShow == 6 || isShow == 7 || isShow == 10" @click="Todetails1(scope.row)">超时处理</el-link>
                <el-link :underline="false" type="warning" v-if="isShow == 8" @click="ReturnVisit(scope.row)">回访</el-link>
                <el-link :underline="false" type="warning" v-if="isShow == 5" @click="genjin(scope.row)">跟进</el-link>
              </template>
            </my-table>

            <!--预约单表格-->
            <my-table
                v-if="isShow == 1"
                ref="myTable"
                :columns="columns1"
                :tableData="List1"
                :cellStyle="cellStyle"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total1"
                :customsFromWhere="where1"
                :loading="loading1"
                @select="select1"
                @ChangeSize="ChangeSize1"
                @currentChange="currentChange1"
            >
              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="warning" @click="Todetails(scope.row)" v-auths="[`${$config.uniquePrefix}orderModule:info:info`]">详情</el-link>
                <el-link :underline="false" type="warning" @click="transfer(scope.row)" v-auths="[`${$config.uniquePrefix}orderModule:myMission:careof`]" v-if="scope.row.order_status != 6">转交</el-link>
                <el-link :underline="false" type="warning" @click="TotakeBack(scope.row)" v-auths="[`${$config.uniquePrefix}orderModule:myMission:takeback`]">收回</el-link>
                <el-link :underline="false" type="warning" style="color: #5976E1!important;" v-if="scope.row.status_label == '待派单'" @click="distributeLeaflets(scope.row)" v-auths="[`${$config.uniquePrefix}orderModule:myMission:grouporder`]">派单</el-link>
              </template>
            </my-table>


          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
            title="列表设置"
            :visible.sync="ListSetupShow"
            width="60%"
            center
            :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

    <!-- 订单详情模块 -->
    <order-details v-if="Orderdetails == true" @showDetalis="ShowDetalis" :data="Detailscurrent" :dict="dict"></order-details>

    <!-- 处理超时 -->
    <abnormalOrder-details v-if="AbnormalOrderdetails == true" :data="detailsData" @showDetalis="ShowDetalis1" />

    <!-- 创建处罚订单或者创建订单组件 -->
    <order-edit v-if="showEdit == true" @show="ShowOrder"  :dict="dict"></order-edit>

    <!--任务转交弹窗组件-->
    <taskTransfer-edit :data="taskTransfercurrent" :selection="selection" :visible.sync="showtaskTransfer" v-if="showtaskTransfer == true" @done="getList"/>

    <!--订单收回弹窗组件-->
    <takeBack-edit :data="takeBackcurrent" :visible.sync="showtakeBack"/>

    <!-- 订单审核弹窗 -->
    <orderReview-edit v-if="showorderReview === true" :data="orderReviewcurrent" :visible.sync="showorderReview" @done="getList"/>

    <!--受理弹窗-->
    <acceptance-edit v-if="showacceptance == true" :dict="dict" :data="current" :visible.sync="showacceptance" @done="show" @refresh="isrefresh"/>

    <!--派单弹窗-->
    <reassignment-edit v-if="showreassignment === true" :data="reassignmentcurrent" :dict="dict" :visible.sync="showreassignment" @freshen="isfreshen1"/>

    <!--添加回访弹窗-->
    <addReturnVisit-edit v-if="showaddReturnVisit === true" :data="addReturnVisitcurrent" :dict="dict" :visible.sync="showaddReturnVisit"  :isShowtousu="isShowtousu" @done="getList"/>

    <!--跟进-->
    <addFollowUp-edit v-if="showaddFollowUp === true" :data="addFollowUptcurrent" :dict="dict" :visible.sync="showaddFollowUp" @done="getList"/>

    

  </div>
</template>

<script>
// 引入创建订单组件
import OrderEdit from '../add/index.vue'
// 引入订单详情组件
import OrderDetails from '../details/index.vue'
// 引入处理状态
import AbnormalOrderDetails from '../abnormalOrderDetails/index.vue'
// 引入任务转交弹窗组件
import TaskTransferEdit from './components/taskTransfer-edit.vue'
// 引入订单收回弹窗组件
import TakeBackEdit from './components/takeBack-edit.vue'
//引入订单审核弹窗
import OrderReviewEdit from '../toBeReviewed/components/orderReview-edit.vue'
//引入添加回访弹窗
import AddReturnVisitEdit from '../pendingFollowUp/components/addReturnVisit-edit.vue'
// 引入跟进
import AddFollowUpEdit from './components/addFollowUp-edit'

// 引入受理
import AcceptanceEdit from '../list/acceptance/acceptance-edit.vue'

// 引入派单
import ReassignmentEdit from "../toBeDispatched/components/reassignment-edit.vue";

// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  getfacilitatorApi,
  set_statusApi
} from '@/api/custom'
import Vue from "vue";
import {my_tasklist,order_list_header} from "@/api/orderModule";


export default {
  // 组件生效
  components: {
    ReassignmentEdit,
    PackageSortElement,
    OrderEdit,
    OrderDetails,
    TaskTransferEdit,
    TakeBackEdit,
    OrderReviewEdit,
    AcceptanceEdit,
    AbnormalOrderDetails,
    AddReturnVisitEdit,
    AddFollowUpEdit
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
        tabs:''
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "订单号",
          // type: "index",
          // width: "70"
          prop: "order_id",
          isShow: true,
          fixed:'left',
        },
        {
          label: "案件号",
          // type: "index",
          // width: "70"
          prop: "case_number",
          isShow: true
        },
        {
          label: "下单时间",
          prop: "order_time",
          isShow: true
        },
        {
          label: "服务商",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "案件地址",
          prop: "starting_point",
          isShow: true
        },
        {
          label: "受理人",
          prop: "acceptor_nickname",
          isShow: true
        },
        {
          label: "客户",
          prop: "order_identity",
          isShow: true
        },
        {
          label: "服务人员",
          prop: "dispatch_nickname",
          isShow: true
        },
        // {
        //   label: "客户",
        //   prop: "facilitator_name",
        //   isShow: true
        // },
        {
          label: "订单来源",
          prop: "channel_name",
          isShow: true
        },
        {
          label: "救援类型",
          prop: "items_name",
          isShow: true
        },
        {
          label: "状态",
          prop: "status_label",
          isShow: true
        },
        // {
        //   label: "操作",
        //   width: "200",
        //   slot: "operationSlot",
        //   isShow: true
        // },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      //选项
      isShow:0,
      activeList:[
        {
          label:'全部'
        },
        // {
        //   label: '待受理'
        // },
        {
          label: '预约超时',
          status: 11,
        },
        {
          label: '受理超时',
          status: 2,
        },
        {
          label: '派单超时',
          status: 3,
        },
        // {
        //   label: '确认超时',
        //   status: 4,
        // },
        // {
        //   label: '指派超时',
        //   status: 5,
        // },
        {
          label: '接单超时',
          status: 6,
        },
        {
          label: '跟进超时',
          status: '',
        },
        {
          label: '到达超时',
          status: 7,
        },
        {
          label: '完成超时',
          status: 8,
        },
        {
          label: '回访超时',
          status: 9,
        },
        {
          label: '审核超时',
          status: 10,
        },
        {
          label: '严重超时',
          status: 1,
        },

      ],

      //下单
      showEdit:false,

      //订单详情
      Detailscurrent:null,
      Orderdetails:false,

      //任务转交
      taskTransfercurrent:null,
      showtaskTransfer:false,


      //预约单表格
      where1: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List1: [],
      // 总条目数
      total1: 0,
      //加载动画
      loading1:true,
      // 表头数据
      columns1: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "故障车牌",
          // type: "index",
          // width: "70"
          prop: "license_plate",
          isShow: true,
          fixed:'left',
        },
        {
          label: "下单时间",
          prop: "order_time",
          isShow: true
        },
        {
          label: "预约时间",
          prop: "appointment_time",
          isShow: true
        },
        {
          label: "距离预约",
          prop: "appointment_label",
          slot: 'appointment_label',
          isShow: true
        },
        {
          label: "救援类型",
          prop: "items_name",
          isShow: true
        },
        {
          label: "服务商",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "案件地址",
          prop: "starting_point",
          isShow: true
        },
        {
          label: "客户",
          prop: "owners",
          isShow: true
        },
        {
          label: "客户手机号",
          prop: "owners_phone",
          isShow: true
        },
        {
          label: "订单来源",
          prop: "channel_name",
          isShow: true
        },
        {
          label: "受理人",
          prop: "acceptor_nickname",
          isShow: true
        },
        // {
        //   label: "确认人",
        //   prop: "",
        //   isShow: true
        // },
        {
          label: "状态",
          prop: "status_label",
          isShow: true
        },
        // {
        //   label: "操作",
        //   width: "200",
        //   slot: "operationSlot",
        //   isShow: true
        // },
      ],
      //订单收回
      takeBackcurrent:null,
      showtakeBack:false,

      //订单审核
      orderReviewcurrent:null,
      //审核弹窗
      showorderReview:false,

      //字典
      dict: {
        type:{}
      },


      //受理
      current:null,
      showacceptance:false,

      //派单
      reassignmentcurrent:null,
      showreassignment:false,

      // 订单详情模块
      detailsData:null,
      AbnormalOrderdetails:false,

      //添加回访
      addReturnVisitcurrent:null,
      showaddReturnVisit:false,
      isShowtousu:false,

      //跟进
      showaddFollowUp:false,
      addFollowUptcurrent:null,


      //当前选择的数据行
      selection:[],

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '订单字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

      // 调用获取自定义表头
      this.getHeader();

    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
      // 获取自定义表头
      getHeader() {
          order_list_header().then(res => {
              // console.log(res)
              // console.log(this.columns)
            // push表头
            res.data.forEach(item => {
              let data = {
                label: item.label,
                prop: item.field,
                isShow: true
              }
              if (this.columns.some(item => JSON.stringify(data) === JSON.stringify(item))) {
                console.log('数组中存在该对象');
              } else {
                console.log('数组中不存在该对象');
                this.columns.push(data)
              }
            })

            // 判断下是否存在了操作列 不存在则追加
            // 使用includes()方法进行判断
            // push操作列
            let data1 = {
              label: "操作",
              width: "200",
              fixed:'right',
              slot: "operationSlot",
              isShow: true
            }
            if (this.columns.some(item => JSON.stringify(data1) === JSON.stringify(item))) {
              console.log('数组中存在操作列');
            } else {
              console.log('数组中不存在操作列');
              this.columns.push(data1)
            }

          })
      },

    // 获取列表
    getList(){
      // 有分页
      if(this.isShow == 0){
        delete this.where.tabs;
      }
      if(this.isShow == 0 || this.isShow == 1 || this.isShow == 10 || this.isShow == 11){
        my_tasklist(this.where).then(async (res) => {
          this.total = res.data.count;
          this.List = res.data.list;
          this.List1 = res.data.list;
          this.loading = false;
          this.loading1 = false;
        }).catch((res) => {
          this.$Message.error(res.msg);
          this.loading = false;
          this.loading1 = false;
        })
      }else {
        // delete this.where.tabs;
        // delete this.where.page;
        // delete this.where.limit;
        // // 没有分页
        // let where = {
        //   tabs: this.activeList[this.isShow].status,
        //   keyword: this.where.keyword,
        //   times: this.where.times,
        // }
        my_tasklist(this.where).then(async (res) => {
          this.total = res.data.count;
          this.List = res.data.list;
          this.List1 = res.data.list;
          this.loading = false;
          this.loading1 = false;
        }).catch((res) => {
          this.$Message.error(res.msg);
          this.loading = false;
          this.loading1 = false;
        })
      }
    },

    //选择时间
    datechange(e){
      this.where.order_time = e;
    },

    // 查询条件
    QueryList(){
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      this.selection = selection;
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
        // 调用获取自定义表头
        this.getHeader();
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 删除
    del(row){
      console.log(row);
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        console.log('点击确定')
      }, "确定", 'warning');
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },


    // 选择tabs
    clickactive(item,index){
      // 当前选择的数据行清空一下
      this.selection = [];

      this.isShow = index;
      this.where.tabs = item.status;

      // 全部不穿状态
      if(item.label == '全部'){
        delete this.where.tabs;
      }

      // 如果包含超时 去掉分页
      if(item.label.includes('超时')){
        console.log('当前tab包括超时')
        delete this.where.page;
        delete this.where.limit;
      }else {
        this.where.page = 1;
        this.where.limit = 10;
      }

        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },

    // 跳转详情
    Todetails(row){
      this.Detailscurrent = row;
      // 显示详情子组件
      this.Orderdetails = true;
    },
    // 接收 详情子组件传递的值
    ShowDetalis(value){
      console.log(value)
      // 隐藏详情子组件
      this.Orderdetails = false;
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },

    // 跳转详情
    Todetails1(row){
      this.detailsData = row;
      // 显示详情子组件
      this.AbnormalOrderdetails = true;
    },
    // 接收 详情子组件传递的值
    ShowDetalis1(value){
      console.log(value)
      // 隐藏详情子组件
      this.AbnormalOrderdetails = false;
      // 调用获取自定义表头
      this.getHeader();
      this.getHeader1();
      this.getList();
    },

    //点击下单
    CreatePenaltyOrder(){
      this.showEdit = true;
    },
    // 接受子组件传递的值
    ShowOrder(value){
      console.log(value);
      // 隐藏子组件
      this.showEdit = value;
        // 调用获取自定义表头
        this.getHeader();
      // 调用一下获取列表的接口
      this.getList();
    },

    //点击转交
    transfer(row){
      this.taskTransfercurrent = row;
      this.showtaskTransfer = true;
    },
    // 批量转交
    batch(row){
      if(this.selection.length == 0){
        this.$Message.error('请选择要转交的订单');
      }else {
        this.taskTransfercurrent = row;
        this.showtaskTransfer = true;
      }
    },


    //预约单表格方法
    // 当前页数改变事件
    currentChange1(val) {
      console.log("当前页数", val)
      this.where.page = val;
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select1(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      this.selection = selection;
      console.log("当前选择的数据行", row)
    },
    ChangeSize1(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
        // 调用获取自定义表头
        this.getHeader();
      this.getList()
    },
    //订单收回
    TotakeBack(row){
      this.takeBackcurrent = row;
      this.showtakeBack = true;
    },

    //点击审核按钮
    ToExamine(row){
      this.orderReviewcurrent = row;
      this.showorderReview = true;
    },

    // 点击接单
    shouli(row){
      this.current = row;
      this.showacceptance = true;
    },
    //接收是否派单组件传递的值
    show(value){
      console.log(value);
      this.showacceptance = value;
      this.showreassignment = true;
      this.reassignmentcurrent = this.current;
    },
    isrefresh(value){
      this.showacceptance = value;
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },

    //点击派单
    distributeLeaflets(row){
      this.reassignmentcurrent = row;
      this.showreassignment = true;
    },
    isfreshen1(value){
        // 调用获取自定义表头
        this.getHeader();
      this.getList();
    },

    //回访按钮
    ReturnVisit(row){
      this.addReturnVisitcurrent = row;
      this.showaddReturnVisit = true;
      this.isShowtousu = false;
    },
    
    //点击跟进
    genjin(row){
      this.addFollowUptcurrent = row;
      this.showaddFollowUp = true
    },

  }

}
</script>

<style lang="scss" scoped>
.table_div{
  border-bottom: 1px solid #eeeeee;;
  margin-bottom: 20px;
}
.table_div_kuai{
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  color: #7b7b7b;
  margin: 10px;
  margin-bottom: 0;
  cursor: pointer;
  padding-bottom: 10px;
}
.table_div_kuai:hover{
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  color: #050505;
  border-bottom: 2px solid #FF9B05;
}
.table_div_kuai1{
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  color: #050505;
  border-bottom: 2px solid #FF9B05;
}
</style>
