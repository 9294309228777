<template>
  <div>
    <div class="title" style="margin-bottom: 0;">
      <div class="title_t">
        <!--<span>订单详情</span>-->
      </div>
      <div class="title_r" @click="returnPage">
        <span>返回</span>
      </div>
    </div>

    <div>
      <el-row style="display: flex;align-items: center;">
        <!--<el-col :span="20" style="display: flex;align-items: center;">-->
        <!--  <span style="font-size: 25px;font-weight: 500;color: #050505;">{{details.header.accident_label}}</span>-->
        <!--  <span style="font-size: 25px;font-weight: 500;color: #050505;margin-left: 20px;">{{details.header.order_id}}（{{details.header.order_status_msg}}）</span>-->
        <!--  <el-button class="custom-button" style="background: #5976E1!important;border-color: #5976E1!important;color: #FFFFFF!important;" @click="openshowReminderEdit">-->
        <!--    <i class="el-icon-cuidan" />-->
        <!--    <span style="vertical-align: middle">催单</span>-->
        <!--  </el-button>-->
        <!--  <el-button class="custom-button" style="background: #3F4157!important;border-color: #3F4157!important;color: #FFFFFF!important;" @click="openshowcomplaintEdit">-->
        <!--    <i class="el-icon-tongzhi" />-->
        <!--    <span style="vertical-align: middle">通知投诉</span>-->
        <!--  </el-button>-->
        <!--</el-col>-->
        <!--<el-col :span="4" style="text-align: right;">-->
        <!--  <span style="font-size: 14px;font-weight: 400;color: #fea837; cursor: pointer;" @click="update">全页刷新</span>-->
        <!--</el-col>-->
      </el-row>

      <el-row :gutter="15" style="margin-top: 20px;display: flex;align-items: center;">
        <el-col :span="12">
          <div class="xuanxianglist">
            <div class="xuanxianglistactive" v-for="(item,index) in list" :key="index"  :class="{xuanxianglistactive1:index==isShow}" @click="activeItem(item,index)">
              <div style="display: flex;align-items: center;justify-content: center;">
                <!--<img :src="item.img" style="width: 16px;height: 16px;margin-right: 10px;">-->
                <span>{{item.name}}</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span='12' style="display: flex;align-items: center;">
          <!--<img src="@/assets/images/home/order/yichang.png" alt="" style="width: 19px;height: 19px;">-->
          <!--<span style="font-size: 20px;font-weight: 500;color: #202033;margin-left: 10px;">订单异常：</span>-->
          <!--<span style="font-size: 20px;font-weight: 500;color: #FF3705;">{{details.explain_msg}}</span>-->
          <!--<span style="-->
          <!--  display: inline-block;-->
          <!--  width: 54px;-->
          <!--  height: 27px;-->
          <!--  background: #e4eaff;-->
          <!--  border: 1px solid rgba(89,118,225,0.27);-->
          <!--  border-radius: 3px;-->
          <!--  font-size: 14px;-->
          <!--  font-weight: 500;-->
          <!--  text-align: center;-->
          <!--  line-height: 25px;-->
          <!--  margin-left: 15px;-->
          <!--  cursor: pointer;-->
          <!--  color: #5976e1;" @click="openshowexceptionHandling()">-->
          <!--  处理-->
          <!--</span>-->
          <el-button @click="update">全页刷新</el-button>
        </el-col>
      </el-row>

      <el-card shadow="never" style="margin-top: 20px;">
        <!--客户工单考核节点-->
        <el-row style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 15px;"></span>
          <span style="font-size: 20px;font-weight: 700;color: #050505;">客户工单考核节点</span>
          <img src="@/assets/images/home/order/wenhao1.png" alt="" style="width: 16px;height: 16px;margin-left: 20px;">
        </el-row>
        <div class="jiedianbg">
          <el-row :gutter="15" style="display: flex;align-items: center;">
            <el-col :span="6" style="display: flex;align-items: center;">
              <!--<img src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;">-->
              <img v-if="details.customer_service_ticket.place_orders_service_accept_practical == true" src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;">
              <img v-else src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;">
              <span style="font-size: 16px;font-weight: 400;color: #050505;margin-left: 15px;">下单到客服受理 ≤ {{details.customer_service_ticket.place_orders_service_accept_stipulation}}</span>
            </el-col>
            <el-col :span="6">
              <!--<span style="font-size: 16px;font-weight: 400;color: #3dc33d;">10秒</span>-->
              <span v-if="details.customer_service_ticket.place_orders_service_accept_overstep == false" style="font-size: 16px;font-weight: 400;color: #3dc33d;">未超出</span>
              <span v-else style="font-size: 16px;font-weight: 400;color: #FF3705;">已超过{{details.customer_service_ticket.place_orders_service_accept_overstep}}</span>
            </el-col>
            <el-col :span="12" >
              <span style="font-size: 16px;font-weight: 400;color: #050505;">{{details.customer_join_times.acceptor_time}}【{{details.customer_join_times.company_name}}  {{details.customer_join_times.acceptor_nickname}}】受理订单    </span>
            </el-col>
          </el-row>
          <el-row :gutter="15" style="display: flex;align-items: center;margin-top: 20px;">
            <el-col :span="6" style="display: flex;align-items: center;">
              <!--<img src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;">-->
              <img v-if="details.customer_service_ticket.place_orders_appoint_driver_practical == true" src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;">
              <img v-else src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;">
              <span style="font-size: 16px;font-weight: 400;color: #050505;margin-left: 15px;">下单到指派司机 ≤ {{details.customer_service_ticket.place_orders_appoint_driver_stipulation}}</span>
            </el-col>
            <el-col :span="6">
              <!--<span style="font-size: 16px;font-weight: 400;color: #FF3705;">10秒</span>-->
              <span v-if="details.customer_service_ticket.place_orders_appoint_driver_overstep == false" style="font-size: 16px;font-weight: 400;color: #3dc33d;">未超出</span>
              <span v-else style="font-size: 16px;font-weight: 400;color: #FF3705;">已超过{{details.customer_service_ticket.place_orders_appoint_driver_overstep}}</span>
            </el-col>
            <el-col :span="12">
              <!--<span style="font-size: 16px;font-weight: 400;color: #050505;">2023-12-14 12:30:54【一键救援  张三三】受理订单    </span>-->
              <!-- <span style="font-size: 16px;font-weight: 400;color: #050505;">{{details.customer_join_times.dispatch_time}}【{{details.customer_join_times.company_name}}  {{details.customer_join_times.dispatch_nickname}}】 指派司机 {{details.customer_join_times.driver_name}}   {{details.customer_join_times.driver_phone}}</span> -->
              <span style="
                display: inline-block;
                text-align: center;
                width: 120px;
                padding-top: 5px;
                padding-bottom: 5px;
                background: #ffffff;
                border: 1px solid #e4e5e9;
                font-size: 16px;
                font-weight: 400;
                color: #ff9b05;
                cursor: pointer;
                border-radius: 6px;" @click="openshowuploadOrderStatus('派单')" v-if="details.status_update.join == false">
                上传派单状态
              </span>
            </el-col>
          </el-row>
          <el-row :gutter="15" style="display: flex;align-items: center;margin-top: 20px;">
            <el-col :span="6" style="display: flex;align-items: center;">
              <img v-if="details.customer_service_ticket.place_orders_driver_join_practical == true" src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;">
              <img v-else src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;">
              <span style="font-size: 16px;font-weight: 400;color: #050505;margin-left: 15px;">下单到司机接单 ≤ {{details.customer_service_ticket.place_orders_driver_join_stipulation}}</span>
            </el-col>
            <el-col :span="6">
              <!--<span style="font-size: 16px;font-weight: 400;color: #050505;">30秒后超时</span>-->
              <span v-if="details.customer_service_ticket.place_orders_driver_join_overstep == false" style="font-size: 16px;font-weight: 400;color: #3dc33d;">未超出</span>
              <span v-else style="font-size: 16px;font-weight: 400;color: #FF3705;">已超过{{details.customer_service_ticket.place_orders_driver_join_overstep}}</span>
            </el-col>
            <el-col :span="12">
              <span style="
                display: inline-block;
                text-align: center;
                width: 120px;
                padding-top: 5px;
                padding-bottom: 5px;
                background: #ffffff;
                border: 1px solid #e4e5e9;
                font-size: 16px;
                font-weight: 400;
                color: #ff9b05;
                cursor: pointer;
                border-radius: 6px;" @click="openshowuploadOrderStatus('接单')" v-if="details.status_update.join == false">
                上传接单状态
              </span>
            </el-col>
          </el-row>
          <el-row :gutter="15" style="display: flex;align-items: center;margin-top: 15px;">
            <el-col :span="6" style="display: flex;align-items: center;">
              <!--<img src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;">-->
              <img v-if="details.customer_service_ticket.place_orders_driver_depart_practical == true" src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;">
              <img v-else src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;">
              <span style="font-size: 16px;font-weight: 400;color: #050505;margin-left: 15px;">下单到司机出发 ≤ {{details.customer_service_ticket.place_orders_driver_depart_stipulation}}</span>
            </el-col>
            <el-col :span="6">
              <span v-if="details.customer_service_ticket.place_orders_driver_depart_overstep == false" style="font-size: 16px;font-weight: 400;color: #3dc33d;">未超出</span>
              <span v-else style="font-size: 16px;font-weight: 400;color: #FF3705;">已超过{{details.customer_service_ticket.place_orders_driver_depart_overstep}}</span>
            </el-col>
            <el-col :span="12">
              <span style="
                display: inline-block;
                text-align: center;
                width: 120px;
                padding-top: 5px;
                padding-bottom: 5px;
                background: #ffffff;
                border: 1px solid #e4e5e9;
                font-size: 16px;
                font-weight: 400;
                color: #5976E1;
                cursor: pointer;
                border-radius: 6px;" @click="openshowuploadOrderStatus('出发')" v-if="details.status_update.depart == false">
                上传出发状态
              </span>
            </el-col>
          </el-row>
          <el-row :gutter="15" style="display: flex;align-items: center;margin-top: 15px;">
            <el-col :span="6" style="display: flex;align-items: center;">
              <!--<img src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;">-->
              <img v-if="details.customer_service_ticket.place_orders_driver_arrive_practical == true" src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;">
              <img v-else src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;">
              <span style="font-size: 16px;font-weight: 400;color: #050505;margin-left: 15px;">下单到司机到达  ≤ {{details.customer_service_ticket.place_orders_driver_arrive_stipulation}}</span>
            </el-col>
            <el-col :span="6">
              <span v-if="details.customer_service_ticket.place_orders_driver_arrive_overstep == false" style="font-size: 16px;font-weight: 400;color: #3dc33d;">未超出</span>
              <span v-else style="font-size: 16px;font-weight: 400;color: #FF3705;">已超过{{details.customer_service_ticket.place_orders_driver_arrive_overstep}}</span>
            </el-col>
            <el-col :span="12">
              <span style="
                display: inline-block;
                text-align: center;
                width: 120px;
                padding-top: 5px;
                padding-bottom: 5px;
                background: #ffffff;
                border: 1px solid #e4e5e9;
                font-size: 16px;
                font-weight: 400;
                color: #53D1A1;
                cursor: pointer;
                border-radius: 6px;" @click="openshowuploadOrderStatus('到达')" v-if="details.status_update.arrive == false">
                上传到达状态
              </span>
            </el-col>
          </el-row>
          <el-row :gutter="15" style="display: flex;align-items: center;margin-top: 15px;">
            <el-col :span="6" style="display: flex;align-items: center;">
              <!--<img src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;">-->
              <img v-if="details.customer_service_ticket.place_orders_driver_finish_practical == true" src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;">
              <img v-else src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;">
              <span style="font-size: 16px;font-weight: 400;color: #050505;margin-left: 15px;">下单到司机完成 ≤ {{details.customer_service_ticket.place_orders_driver_finish_stipulation}}</span>
            </el-col>
            <el-col :span="6">
              <span v-if="details.customer_service_ticket.place_orders_driver_finish_overstep == false" style="font-size: 16px;font-weight: 400;color: #3dc33d;">未超出</span>
              <span v-else style="font-size: 16px;font-weight: 400;color: #FF3705;">已超过{{details.customer_service_ticket.place_orders_driver_finish_overstep}}</span>
            </el-col>
            <el-col :span="12">
              <span style="
                display: inline-block;
                text-align: center;
                width: 120px;
                padding-top: 5px;
                padding-bottom: 5px;
                background: #ffffff;
                border: 1px solid #e4e5e9;
                font-size: 16px;
                font-weight: 400;
                color: #F35081;
                cursor: pointer;
                border-radius: 6px;" @click="openshowuploadOrderStatus('完成')" v-if="details.status_update.finish == false">
                上传完成状态
              </span>
            </el-col>
          </el-row>
        </div>

        <!--客户工单考核节点-->
        <el-row style="display: flex;align-items: center;margin-top: 20px;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 15px;"></span>
          <span style="font-size: 20px;font-weight: 700;color: #050505;">服务工单考核节点</span>
        </el-row>
        <div class="jiedianbg">
          <el-row :gutter="15" style="display: flex;align-items: center;">
            <el-col :span="6" style="display: flex;align-items: center;">
              <img v-if="details.driver_service_ticket.send_orders_driver_practical == true" src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;">
              <img v-else src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;">
              <span style="font-size: 16px;font-weight: 400;color: #050505;margin-left: 15px;">派单到司机接单 ≤ {{details.driver_service_ticket.send_orders_driver_stipulation}}</span>
            </el-col>
            <el-col :span="6">
              <span v-if="details.driver_service_ticket.send_orders_driver_overstep == false" style="font-size: 16px;font-weight: 400;color: #3dc33d;">未超出</span>
              <span v-else style="font-size: 16px;font-weight: 400;color: #FF3705;">已超过{{details.driver_service_ticket.send_orders_driver_overstep}}</span>
            </el-col>
            <el-col :span="12" v-if="details.driver_join_times.order_status == 3 || details.driver_join_times.order_status == 4 || details.driver_join_times.order_status == 5 || details.driver_join_times.order_status == 6">
              <span style="font-size: 16px;font-weight: 400;color: #050505;">{{details.driver_join_times.receiving_time}}【{{details.driver_join_times.company_name}}  {{details.driver_join_times.driver_name}}】接受订单    </span>
            </el-col>
          </el-row>
          <el-row :gutter="15" style="display: flex;align-items: center;margin-top: 20px;">
            <el-col :span="6" style="display: flex;align-items: center;">
              <img v-if="details.driver_service_ticket.driver_join_to_depart_practical == true" src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;">
              <img v-else src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;">
              <span style="font-size: 16px;font-weight: 400;color: #050505;margin-left: 15px;">司机接单到司机出发 ≤ {{details.driver_service_ticket.driver_join_to_depart_stipulation}}</span>
            </el-col>
            <el-col :span="6">
              <!--<span style="font-size: 16px;font-weight: 400;color: #FF3705;">已超过50秒</span>-->
              <span v-if="details.driver_service_ticket.driver_join_to_depart_overstep == false" style="font-size: 16px;font-weight: 400;color: #3dc33d;">未超出</span>
              <span v-else style="font-size: 16px;font-weight: 400;color: #FF3705;">已超过{{details.driver_service_ticket.driver_join_to_depart_overstep}}</span>
            </el-col>
            <el-col :span="12">
              <!--<span style="-->
              <!--  display: inline-block;-->
              <!--  text-align: center;-->
              <!--  width: 120px;-->
              <!--  padding-top: 5px;-->
              <!--  padding-bottom: 5px;-->
              <!--  background: #ffffff;-->
              <!--  border: 1px solid #e4e5e9;-->
              <!--  font-size: 16px;-->
              <!--  font-weight: 400;-->
              <!--  color: #ff9b05;-->
              <!--  cursor: pointer;-->
              <!--  border-radius: 6px;" @click="openshowaddFollowUp()">-->
              <!--  订单跟进-->
              <!--</span>-->
            </el-col>
          </el-row>
          <el-row :gutter="15" style="display: flex;align-items: center;margin-top: 20px;">
            <el-col :span="6" style="display: flex;align-items: center;">
              <img v-if="details.driver_service_ticket.driver_depart_to_arrive_practical == true" src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;">
              <img v-else src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;">
              <span style="font-size: 16px;font-weight: 400;color: #050505;margin-left: 15px;">司机出发到司机到达 ≤ {{details.driver_service_ticket.driver_depart_to_arrive_stipulation}}</span>
            </el-col>
            <el-col :span="6">
              <!--<span style="font-size: 16px;font-weight: 400;color: #050505;">50秒</span>-->
              <span v-if="details.driver_service_ticket.driver_depart_to_arrive_overstep == false" style="font-size: 16px;font-weight: 400;color: #3dc33d;">未超出</span>
              <span v-else style="font-size: 16px;font-weight: 400;color: #FF3705;">已超过{{details.driver_service_ticket.driver_depart_to_arrive_overstep}}</span>
            </el-col>
            <el-col :span="12">

            </el-col>
          </el-row>
          <el-row :gutter="15" style="display: flex;align-items: center;margin-top: 20px;">
            <el-col :span="6" style="display: flex;align-items: center;">
              <img v-if="details.driver_service_ticket.driver_arrive_to_finish_practical == true" src="@/assets/images/home/order/chenggong.png" alt="" style="width: 24px;height: 24px;">
              <img v-else src="@/assets/images/home/order/shibai.png" alt="" style="width: 24px;height: 24px;">
              <span style="font-size: 16px;font-weight: 400;color: #050505;margin-left: 15px;">司机到达到订单完成 ≤ {{details.driver_service_ticket.driver_arrive_to_finish_stipulation}}</span>
            </el-col>
            <el-col :span="6">
              <!--<span style="font-size: 16px;font-weight: 400;color: #050505;">50秒</span>-->
              <span v-if="details.driver_service_ticket.driver_arrive_to_finish_overstep == false" style="font-size: 16px;font-weight: 400;color: #3dc33d;">未超出</span>
              <span v-else style="font-size: 16px;font-weight: 400;color: #FF3705;">已超过{{details.driver_service_ticket.driver_arrive_to_finish_overstep}}</span>
            </el-col>
            <el-col :span="12">

            </el-col>
          </el-row>
        </div>
      </el-card>

      <!-- 任务跟进记录 -->
      <el-card shadow="never">
        <el-row style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 20px;font-weight: 700;color: #050505;margin-right: 20px;">任务跟进记录</span>
          <!--<el-button class="custom-button" style="background: #53D1A1!important;border-color: #53D1A1!important;color: #FFFFFF!important;" @click="openshowaddFollowUp">-->
          <!--  <i class="el-icon-tianjia" />-->
          <!--  <span style="vertical-align: middle">跟进</span>-->
          <!--</el-button>-->
        </el-row>
        <div class="ageing_b" style="padding: 20px;">
          <!--时间轴的每一项-->
          <div style="display: flex;align-items: center;margin-bottom: 60px;" v-for="(item,index) in timeList" :key="index">
            <div style="width: 48px;height: 48px;background: #FFECCD;border-radius: 50%;margin-left: 20px;display: flex;align-items: center;justify-content: center;">
              <div style="width: 24px;height: 24px;background: #FF9B05;border-radius: 50%;">
                <div class="xian" v-if="index != lastItem"></div>
              </div>
            </div>
            <div style="margin-left: 20px;">
              <div style="font-size: 14px;font-weight: 400;color: #878787;">
                <span style="margin-right: 10px;">{{item.event_name}}</span>
                <span>{{item.timestamp}}</span>
              </div>
              <div>
                <span style="font-size: 16px;font-weight: 400;color: #050505;">{{item.description}}</span>
              </div>
            </div>
          </div>
          <el-empty v-if="timeList.length == 0" description="暂无数据"></el-empty>
        </div>
      </el-card>

      <!--接单信息-->
      <el-card shadow="never">
        <el-row style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 20px;font-weight: 700;color: #050505;">接单信息</span>
        </el-row>

        <el-row :gutter="15" style="margin-top: 15px;">
          <el-col :span="12">
            <el-row :gutter="15" style="margin-bottom: 15px;">
              <el-col :span="8">
                <span style="font-size: 16px;font-weight: 400;color: #868792;">预计到达里程：</span>
                <span style="font-size: 16px;font-weight: 400;color: #050505;">{{details.kilometer_information.expected_arrival_course}}</span>
              </el-col>
              <el-col :span="8">
                <span style="font-size: 16px;font-weight: 400;color: #868792;">预计背车里程：</span>
                <span style="font-size: 16px;font-weight: 400;color: #050505;">{{details.kilometer_information.expected_return_mileage}}</span>
              </el-col>
              <el-col :span="8">
                <span style="font-size: 16px;font-weight: 400;color: #868792;">预计费用：</span>
                <span style="font-size: 16px;font-weight: 400;color: #050505;">￥{{details.kilometer_information.expected_price}}</span>
              </el-col>
            </el-row>
            <el-row :gutter="15" style="margin-bottom: 15px;">
              <el-col :span="8">
                <span style="font-size: 16px;font-weight: 400;color: #868792;">到达现场效验：</span>
                <span style="font-size: 16px;font-weight: 400;color: #050505;">{{details.kilometer_information.field_calibration}}</span>
              </el-col>
              <el-col :span="8">
                <span style="font-size: 16px;font-weight: 400;color: #868792;">实际到达里程：</span>
                <span style="font-size: 16px;font-weight: 400;color: #050505;">{{details.kilometer_information.actual_arrival_course}}km</span>
              </el-col>
              <el-col :span="8">
                <span style="font-size: 16px;font-weight: 400;color: #868792;">实际背车里程：</span>
                <span style="font-size: 16px;font-weight: 400;color: #050505;">{{details.kilometer_information.actual_return_mileage}}</span>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="8">
                <span style="font-size: 16px;font-weight: 400;color: #868792;">实际费用：</span>
                <span style="font-size: 16px;font-weight: 400;color: #050505;">￥{{details.kilometer_information.actual_price}}</span>
              </el-col>
              <el-col :span="10">
                <!--<div style="cursor: pointer;display: inline-block;">-->
                <!--  <img style="width: 24px;height: 24px; vertical-align: middle;" src="@/assets/images/home/order/xuanzhong.png" alt="">-->
                <!--  <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle; margin-left: 5px;">申请规划</span>-->
                <!--</div>-->
                <!--<div style="cursor: pointer;display: inline-block;">-->
                <!--  <img style="width: 24px;height: 24px; vertical-align: middle; margin-left: 15px;" src="@/assets/images/home/order/weixuanzhong.png" alt="">-->
                <!--  <span style="font-size: 16px;font-weight: 400;text-align: right;color: #050505; vertical-align: middle; margin-left: 5px;">实际规划</span>-->
                <!--</div>-->
                <el-radio-group v-model="project" @change="selectproject">
                  <el-radio label="apply">申请规划</el-radio>
                  <el-radio label="practical">实际规划</el-radio>
                </el-radio-group>
              </el-col>
            </el-row>
            <div style="margin-top: 15px;">
              <!-- 地图 -->
              <div style="height: 300px;" ref="element">
                <AMapLoader v-if="lazyLoading === true" :msgData="msgData" v-bind:locationorigin="locationorigin" :locationdestination="locationdestination" :coord="coord" :is="currentComponent"></AMapLoader>
              </div>
            </div>
          </el-col>

          <el-col :span="12">
            <div style="background: #f7f8fa;border: 1px solid #e3e4e9;border-radius: 6px;padding: 20px;">
              <div style="margin-bottom: 10px;">
                <span style="font-size: 16px;font-weight: 400;color: #868792;margin-right: 10px;">下单时间：</span>
                <span style="font-size: 16px;font-weight: 400;color: #868792;">{{details.join_info.order_time}}</span>
              </div>
              <div style="margin-bottom: 10px;">
                <span style="font-size: 16px;font-weight: 400;color: #868792;margin-right: 10px;">故障车牌号：</span>
                <span style="font-size: 16px;font-weight: 400;color: #868792;">{{details.join_info.license_plate}}</span>
              </div>
              <div style="margin-bottom: 10px;">
                <span style="font-size: 16px;font-weight: 400;color: #868792;margin-right: 10px;">故障车架号：</span>
                <span style="font-size: 16px;font-weight: 400;color: #868792;">{{details.join_info.vin}}</span>
              </div>
              <div style="margin-bottom: 10px;">
                <span style="font-size: 16px;font-weight: 400;color: #868792;margin-right: 10px;">客户姓名：</span>
                <span style="font-size: 16px;font-weight: 400;color: #868792;">{{details.join_info.owners}}</span>
              </div>
              <div style="margin-bottom: 10px;display: flex;align-items: center;">
                <span style="font-size: 16px;font-weight: 400;color: #868792;margin-right: 10px;">客户电话：</span>
                <span style="font-size: 16px;font-weight: 400;color: #868792;">{{details.join_info.owners_phone}}</span>
                <img src="@/assets/images/home/order/phone.png" alt="" style="width: 18px;height: 18px;" @click="tel(details.join_info.owners_phone,'客户')">
              </div>
              <div style="margin-bottom: 10px;">
                <span style="font-size: 16px;font-weight: 400;color: #868792;margin-right: 10px;">发包方：</span>
                <span style="font-size: 16px;font-weight: 400;color: #868792;">{{details.join_info.company_name}}</span>
              </div>
              <div style="margin-bottom: 10px;">
                <span style="font-size: 16px;font-weight: 400;color: #868792;margin-right: 10px;">服务商：</span>
                <span style="font-size: 16px;font-weight: 400;color: #868792;">{{details.join_info.outsourcer}}</span>
              </div>
              <div style="margin-bottom: 10px;">
                <span style="font-size: 16px;font-weight: 400;color: #868792;margin-right: 10px;">司机姓名：</span>
                <span style="font-size: 16px;font-weight: 400;color: #868792;">{{details.join_info.driver_name}}</span>
              </div>
              <div style="margin-bottom: 10px;display: flex;align-items: center;">
                <span style="font-size: 16px;font-weight: 400;color: #868792;margin-right: 10px;">司机电话：</span>
                <span style="font-size: 16px;font-weight: 400;color: #868792;">{{details.join_info.driver_phone}}</span>
                <img src="@/assets/images/home/order/phone.png" alt="" style="width: 18px;height: 18px;" @click="tel(details.join_info.driver_phone,'司机')">
              </div>
              <div style="margin-bottom: 10px;">
                <span style="font-size: 16px;font-weight: 400;color: #868792;margin-right: 10px;">免拖里程：</span>
                <span style="font-size: 16px;font-weight: 400;color: #868792;">{{details.join_info.free_trailer}}km</span>
              </div>
            </div>
          </el-col>

        </el-row>

      </el-card>

    </div>

    <!--催单弹窗组件-->
    <reminder-edit v-if="showReminderEdit == true" :data="current" :visible.sync="showReminderEdit" @done="getDetails"/>

    <!--发送投诉弹窗组件-->
    <complaint-edit :data="complaintcurrent" :visible.sync="showcomplaintEdit"/>

    <!-- 添加跟进弹窗 -->
    <addFollowUp-edit :data="addFollowUpcurrent" :visible.sync="showaddFollowUp"/>

    <!--异常处理弹窗-->
    <exceptionHandling-edit v-if="showexceptionHandling == true" :data="exceptionHandlingcurrent" :visible.sync="showexceptionHandling" @done="getDetails"/>

    <!--上传订单状态弹窗-->
    <uploadOrderStatus-edit v-if="showuploadOrderStatus == true" :value="value" :data="uploadOrderStatuscurrent" :visible.sync="showuploadOrderStatus" @done="getDetails"/>

    <!--上传派单状态-->
    <dispatch-driver v-if="showdispatch == true" :data="dispatchcurrent" :visible.sync="showdispatch" @done="getDetails"/>

    <!--上传出发状态-->
    <go-edit v-if="showgo == true" :data="gocurrent" :visible.sync="showgo" @done="getDetails"/>

    <!--上传到达状态-->
    <reach-edit v-if="showreach == true" :data="reachcurrent" :visible.sync="showreach" @done="getDetails"/>

    <!--上传完成状态-->
    <complete-edit v-if="showcomplete == true" :data="completecurrent" :visible.sync="showcomplete" @done="getDetails"/>

  </div>
</template>

<script>
  //引入催单弹窗组件
  import ReminderEdit from './components/reminder-edit.vue'
  //引入发送投诉弹窗组件
  import ComplaintEdit from './components/complaint-edit.vue'
  // 引入添加跟进弹窗
  import AddFollowUpEdit from './components/addFollowUp-edit.vue'
  //异常处理弹窗
  import ExceptionHandlingEdit from './components/exceptionHandling-edit.vue'
  //上传接单状态弹窗
  import UploadOrderStatusEdit from './components/uploadOrderStatus-edit.vue'
  //引入上传派单状态
  import DispatchDriver from './components/dispatch-driver.vue'
  //引入上传出发状态
  import GoEdit from './components/go-edit.vue'
  // 引入到达状态
  import ReachEdit from './components/reach-edit.vue'
  // 引入完成状态
  import CompleteEdit from './components/complete-edit.vue'

  // 引入的接口
  import {abnormaldetails,details_kilometre} from "@/api/orderModule";
  import {get_tel_no,} from '@/api/phone'

  export default {
    components:{
      ReminderEdit,
      ComplaintEdit,
      AddFollowUpEdit,
      ExceptionHandlingEdit,
      UploadOrderStatusEdit,
      DispatchDriver,
      GoEdit,
      ReachEdit,
      CompleteEdit
    },
    props: {
      data: Object
    },
    data(){
      return{
        list:[
          {
            img: require('../../../assets/images/home/genjindianhuaactive.png'),
            name:'拨打车主电话'
          },
          {
            img: require('../../../assets/images/home/genjindianhua.png'),
            name:'拨打订单来源客户电话'
          },
          {
            img: require('../../../assets/images/home/genjindianhua.png'),
            name:'拨打下游服务商电话'
          },
        ],
        isShow:0,

        timeList:[
          // {
          //   time:'2022.09.18  14:30',
          //   content:'【同城救援   张三】'
          // },
          // {
          //   time:'2022.09.18  14:30',
          //   content:'【同城救援 张三】完成救援成功'
          // },
          // {
          //   time:'2022.09.18  14:30',
          //   content:'【同城救援 张三】操作客户工单已接单，接单点：深圳市汉海达大厦（113.79152,23.3262255）本次实际接单节点将不回传'
          // },
          // {
          //   time:'2022.09.18  14:30',
          //   content:'状态变更 已延期 原因:司机还未到达现场，十分钟后跟进'
          // },
          // {
          //   time:'2022.09.18  14:30',
          //   content:'司机接单超时，责任人:张三三，司机 张三三 13800000001'
          // },
          // {
          //   time:'2022.09.18  14:30',
          //   content:'平台受理'
          // }
        ],
        lastItem:0,

        //地图组件延时加载
        lazyLoading:true,
        //高度
        msgData:0,
        currentComponent: 'AMapLoader', // 初始显示 MyComponent 组件

        //催单弹窗组件
        current:null,
        showReminderEdit:false,

        //投诉弹窗组件
        complaintcurrent:null,
        showcomplaintEdit:false,

        //添加跟进
        addFollowUpcurrent:null,
        showaddFollowUp:false,

        //异常处理
        exceptionHandlingcurrent:null,
        showexceptionHandling:false,

        //上传订单状态
        uploadOrderStatuscurrent:null,
        showuploadOrderStatus:false,
        value:'',

        //上传派单
        showdispatch:false,
        dispatchcurrent:null,

        //上传出发状态
        gocurrent:null,
        showgo:false,

        //上传到达状态
        reachcurrent:null,
        showreach:false,

        //上传完成状态
        completecurrent:null,
        showcomplete:false,

        //详情数据
        details:{
          header:{},
          join_info:{},
          // 地图相关
          kilometer_information:{
            actual_arrival_course:'',
            actual_price:'',
            actual_return_mileage:'',
            actual_total_distance:'',
            coord:[],
            destination_coord:[],
            details:'',
            expected_arrival_course:'',
            expected_price:'',
            expected_return_mileage:'',
            expected_total_distance:'',
            field_calibration:'',
            is_plan:'',
            starting_point_coord:[]
          },
          driver_service_ticket:{},
          timer_shaft:{},
          driver_join_times:{},
          customer_service_ticket:{},
          owners_phone:'',
          client_phone:'',
          second_facilitator_phone:'',
          explain_msg:'',
          status_update:{}
        },

        //用户传递的经度纬度
        locationorigin:[],
        locationdestination:[],

        // 规划
        project:'apply',
        // 实际规划
        coord:[],

        //上传派单司机
        dialogVisible:false,

      }
    },

    mounted() {
      //获取地图左侧内容的高度 传值给地图子组件
      // this.msgData = this.$refs.element.offsetHeight - 20;  //减去的20是内边距距离
      this.msgData = 300 - 20;  //减去的20是内边距距离
      //加载地图组件
      // this.lazyLoading = true;


      console.log(this.data)

      // 获取详情
      this.getDetails();
    },

    methods:{
      // 返回按钮
      returnPage(){
        this.$emit('showDetalis',false) ;
      },

      // 全页刷新
      update(){
        this.getDetails();
      },

      // 点击拨打电话
      tel(phone,tel_type){
        get_tel_no().then(res => {
          if(res.data.is_exist == 0){

            // 显示拨打电话弹窗
            let data = {
              showPhone: true
            }
            this.$addStorageEvent(1, "showPhone", JSON.stringify(data))
            // 要拨打的手机号
            let dianhua = {
              phone: phone
            }
            this.$addStorageEvent(1, "phone", JSON.stringify(dianhua))

            // 获取 订单号id
            let orderid = {
              orderid: this.data.id,
            }
            this.$addStorageEvent(1, "orderid", JSON.stringify(orderid))

            // 获取呼出类型
            let teltype = {
              tel_type: tel_type
            }
            this.$addStorageEvent(1, "tel_type", JSON.stringify(teltype))
          }else {
            this.$message.error('暂无坐席')
          }
        })
      },

      // 获取详情
      getDetails(){
        abnormaldetails(this.data.id).then(res => {
          console.log(res)
          this.details = res.data;

          //给时间轴默认添加线
          this.timeList = res.data.timer_shaft;
          if(res.data.timer_shaft.length != 0){
            this.timeList.forEach(item => {
              item.isXian = true;
            })
            //获取数组的最后一项
            this.lastItem = this.timeList.length - 1;
            this.timeList[this.lastItem].isXian = false;
          }

          // 获取申请规划还是实际规划路线
          this.getGongli();
        })
      },

      //获取订单公里信息详情接口
      getGongli(){
        let where = {
          odd: this.data.order_id,
          plan: this.project
        }
        details_kilometre(this.data.id,where).then(res => {
          console.log(res)
          if(res.code == 200){
            // 申请路线 实际路线
            this.coord = res.data;
            // 刷新下地图组件
            this.lazyLoading = false;
            this.$nextTick(() => {
              this.lazyLoading = true;
            })
          }else {
            this.$message.error(res.data.msg);
          }
        })
      },
      //选择申请规划或者实际规划
      selectproject(value){
        console.log(value);
        // 是申请规划还是实际规划
        if(value == 'practical'){
          // 实际规划
          this.project = 'practical'
          this.getGongli();
        }else {
          // 申请规划
          this.practical = 'apply'
          this.getGongli();
        }
      },

      //选择类型
      activeItem(item,index) {
        console.log(item);
        console.log(index);
        this.isShow = index;
        if (item.name == '车主电话') {
          this.list[0].img = require('../../../assets/images/home/genjindianhuaactive.png');
          this.list[1].img = require('../../../assets/images/home/genjindianhua.png');
          this.list[2].img = require('../../../assets/images/home/genjindianhua.png');
          this.tel(this.details.owners_phone,'客户')
        } else if (item.name == '拨打订单来源客户电话') {
          this.list[0].img = require('../../../assets/images/home/genjindianhua.png');
          this.list[1].img = require('../../../assets/images/home/genjindianhuaactive.png');
          this.list[2].img = require('../../../assets/images/home/genjindianhua.png');
          this.tel(this.details.client_phone,'其他')
        } else if (item.name == '拨打下游服务商电话') {
          this.list[0].img = require('../../../assets/images/home/genjindianhua.png');
          this.list[1].img = require('../../../assets/images/home/genjindianhua.png');
          this.list[2].img = require('../../../assets/images/home/genjindianhuaactive.png');
          this.tel(this.details.second_facilitator_phone,'服务商')
        }
      },

      //点击催单按钮
      openshowReminderEdit(){
        this.current = this.data;
        this.showReminderEdit = true;
      },

      //点击发送投诉按钮
      openshowcomplaintEdit(){
        this.showcomplaintEdit = true;
      },

      //点击跟进按钮
      openshowaddFollowUp(){
        this.showaddFollowUp = true;
      },

      //点击处理按钮
      openshowexceptionHandling(){
        this.exceptionHandlingcurrent = this.data;
        this.showexceptionHandling = true;
      },

      //上传状态按钮点击事件
      openshowuploadOrderStatus(value){
        if(value == '派单'){
          this.dispatchcurrent = this.data;
          this.showdispatch = true;
        }else if(value == '接单'){
          this.uploadOrderStatuscurrent = this.data;
          // this.value = value;
          this.showuploadOrderStatus = true;
        }else if(value == '出发'){
          this.gocurrent = this.data;
          this.showgo = true;
        }else if(value == '到达'){
          this.reachcurrent = this.data;
          this.showreach = true;
        }else if(value == '完成'){
          this.showcomplete = true;
          this.completecurrent = this.data;
        }
      },

    },

  }
</script>

<style lang="scss" scoped>
  .xuanxianglist{

    .xuanxianglistactive{
      width: 29%;
      cursor: pointer;
      background: #e8e8e8;
      border: 1px solid #dadada;
      border-radius: 6px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 14px;
      font-weight: 500;
      color: #7b7b7b;
      text-align: center;
      margin-top: 0;
      margin-right: 10px;
      display: inline-block;
    }
    .xuanxianglistactive1{
      background: #FF9B05;
      color: #FFFFFF;
      border-color: #FF9B05;
    }

    //最后一个
    .xuanxianglistactive:last-child{
      margin-right: 0;
    }

  }

  .jiedianbg{
    padding: 20px;
    background: #ffffff;
    border: 1px solid #e3e4e9;
    border-radius: 6px;
    margin-top: 10px;
  }

  .xian{
    width: 1px;
    height: 100px;
    border: 3px dashed #ff9b05;
    margin: auto;
  }
</style>
